<template>
	<div class="table-responsive">
		<table class="table table-striped table-sm">
			<thead>
				<tr>
					<th key="id" class="d-none">
						<button class="no-border" @click="recordsSort(id)" >#</button>
					</th>
					<th key="weekday">
						<button class="no-border" >Dia</button>
					</th>
					<th key="starttime">
						<button class="no-border" @click="recordsSort(starttime)" >Horário</button>
					</th>
					<th>Ações</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="record in records" :key="record.id" :class="`${record.unavailable.toString() === 'true' ? 'unavailable' : ''}`">
					<td key="id" class="d-none" >
						{{ record.id }}
					</td>
					<td key="weekday" >
						{{ this.weekdays[record.weekday] }}
					</td>
					<td key="starttime" >
						{{ record.allday ? 'Dia todo' : `${record.starttime} - ${record.endtime}` }}
					</td>
					<td>
						<router-link class="btn btn-dark" :to="{ name: routeTo, params: { id: record.id }}" v-if="!!routeTo" >
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
								<path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
								<path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
							</svg>
						</router-link>
						<button type="button" class="btn btn-dark ml-1" @click="deleteRecord(record.id)" v-if="!showBtnDelete" >
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eraser" viewBox="0 0 16 16">
								<path d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828l6.879-6.879zm2.121.707a1 1 0 0 0-1.414 0L4.16 7.547l5.293 5.293 4.633-4.633a1 1 0 0 0 0-1.414l-3.879-3.879zM8.746 13.547 3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293l.16-.16z"/>
							</svg>
						</button>
						<!-- <a :href="routeToUrl + record.id" class="btn btn-success" v-if="routeToUrl"><span class="glyphicon glyphicon-pencil"></span></a>
						<a type="button" :href="'/pessoas/atendimento/' + record.id" data-toggle="tooltip" title="" class="btn btn-success hidden-xs" data-original-title="Prontuário" v-if="object == 'people'"><span class="glyphicon glyphicon-list-alt"></span></a> -->
					</td>
				</tr>
			</tbody>
		</table>
		<div class="container">
			<div class="col-md-12 text-center">
				<button type="button" class="btn btn-sm btn-outline-secondary" @click="page = 1"> &lt;&lt; </button>
				<button type="button" class="btn btn-sm btn-outline-secondary" v-if="page != 1" @click="page--"> Anterior </button>
				<button type="button" class="btn btn-sm btn-outline-secondary" :class="pageNumber === page && 'bg-theme3'" v-for="pageNumber in pagesLoaded" :key="pageNumber" @click="page = pageNumber"> {{pageNumber}} </button>
				<button type="button" @click="page++" v-if="page < pages && pages" class="btn btn-sm btn-outline-secondary"> Próximo </button>
				<button type="button" class="btn btn-sm btn-outline-secondary" @click="page = pages"> >> </button>
			</div>
		</div>
	</div>
</template>

<script>
import { sAlert, sDialog } from '../utils/jalerts';
import ApiAxios from '../../services/api-axios';

export default {
	props: {
		specificEndpoint: String,
		object: String,
		routeNew: String,
		routeTo: String,
		routeToUrl: String,
		showBtnDelete: Boolean
	},
	components: {
	},
	data() {
		return {
			records: [],
			strFields: '',
			page: 1,
			size: 10,
			pages: 0,
			sort: '',
			param: '',
			weekdays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
			filter: { inactive: false },
		};
	},
	watch: {
		page() {
			this.fetchGetData();
		}
	},
	computed: {
		pagesLoaded() {
			let pagesVisible = [];
			
			let first = this.page - 3 > 1 ? this.page - 3 : 1;
			let last = this.page + 3 < this.pages ? this.page + 3 : this.pages;

			for (let i = first; i <= last; i++) { pagesVisible.push(i); }

			return pagesVisible;
		},
		filters() {
			if (this.param) return { [this.filter]: this.param };

			return {};
		},
		unavailable: function(value) {
			return value ? 'unavailable' : 'unavailable';
		}
	},
	methods: {
		callSearch(e) {
			if (e.keyCode === 13) this.search();
		},
		async fetchGetData() {
			this.$root.loadingVisible = true;
			ApiAxios.get(`${this.specificEndpoint || this.object}`, {
				params: {
					fields: 'id, starttime, endtime, weekday, allday, unavailable',
					page: this.page - 1,
					sort: this.sort,
					filter: this.filter,
				}
			})
			.then((res) => {
				this.records = res.data[this.object];
				this.pages = res.data.totalPages;
				this.$root.loadingVisible = false;
			})
			.catch((e) => {
				this.$root.loadingVisible = false;
				if (e.response.status == 403) {
					this.$router.back();
					return sAlert({ type: 'warning', text: 'Ops, ' + e.response.data });  
				}
				sAlert({ type: 'danger', text: 'Ops, houve um erro: ' + e.response.data });
			});
		},
		recordsSort(fieldName) {
			this.sort = fieldName;
			if(this.page === 1) { return this.fetchGetData(); }
			this.page = 1;
		},
		search() {
			if(this.page === 1) { return this.fetchGetData(); }
			this.page = 1;
		},
		deleteRecord(id) {
			let that = this;
			sDialog({
				text: 'Confirma a exclusão do registro?',
				confirmFunction() {
					ApiAxios.delete(`locationAvailabilities/${id}`)
						.then(() => {
							that.fetchGetData();
							sAlert({ type: 'success', text: 'Registro excluído com sucesso.' });
						})
						.catch((e) => {
							if (e.response && e.response.status == 403) {
								return sAlert({ type: 'warning', text: 'Ops, ' + e.response.data });  
							}
							sAlert({ type: 'danger', text: 'Ops, houve um erro: ' + e.response.data });
						});  
				},
			})
		},


	},
	created () {
		this.fetchGetData();
	}
}
</script>

<style>
	.unavailable {
		background-color: rgba(255, 46, 46, 0.3) !important; 
	}               
</style>
