
<template>
  <div class="container my-3 p-3 bg-light rounded box-shadow">
    <form @submit.prevent="validateAndSubmit" id="formUser" >
      <div class="form-row">
        <div class="form-group col-md-9">
          <label for="name">Nome</label>
          <input type="text" class="form-control" id="name" placeholder="Nome" v-model="location.name" autofocus="autofocus" required="required">
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-4">
          <label>Grupo</label>
          <InputSelect collection="locationGroups" object="locationGroup" fieldKey="id" fieldText="description" v-bind:current="location.location_group_id" @select="(sel) => location.location_group_id = sel" rows="200"/>
        </div>
        <div class="form-group col-md-3">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="showtomember" v-model="location.showtomember">
            <label class="form-check-label" for="showtomember">
              Mostrar para associados
            </label>
          </div>
        </div>
        <div class="form-group col-md-3">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="inative">
            <label class="form-check-label" for="inative">
              Inativo
            </label>
          </div>
        </div>
      </div>
      
      <FormAvailability v-bind:location_id="this.$route.params.id" @saved="refetchAvailabilities" />

      <AvailabilityDataTable v-if="this.$route.params.id" ref="listAvailabilities" v-bind:specificEndpoint="tableOptions.endpoint" v-bind:object="tableOptions.object" v-bind:routeToUrl="null" />

      <div style="float: right;">
        <button type="button" class="btn btn-primary" v-on:click="validateAndSubmit">Salvar</button>
        <router-link class="btn btn-default" style="margin-left:10px;" to="/locations">Fechar</router-link>
      </div>
    </form>
  </div>
</template>

<script>
import ApiAxios from '../../services/api-axios';
import { sAlert } from '../utils/jalerts';
import AvailabilityDataTable from './AvailabilityDataTable.vue';
import FormAvailability from './FormAvailability.vue';
import InputSelect from '../commons/InputSelect.vue';

export default {
  props: {
    save: Function,
  },
  components: {
    AvailabilityDataTable,
    FormAvailability,
    InputSelect
  },
  data() {
    return {
      location: {},
      validationErrors: [],
      tableOptions: {
        endpoint: `locations/${this.$route.params.id}/availabilities`,
        object: 'availabilities',
      }
    };
  },
  mounted() {
    this.fetchGetLocation();
  },
  methods: {
    refetchAvailabilities() {
      this.$refs.listAvailabilities.fetchGetData();
    },
    async fetchGetLocation() {
      if(!this.$route.params.id) return;
      this.$root.loadingVisible = true;
      const res = await ApiAxios.get(`locations/${this.$route.params.id}`);
      this.location = res.data.location;
      this.$root.loadingVisible = false;
    },
    validateAndSubmit() {
      this.validationErrors = [];
      if (!this.validate()) {
        return sAlert({
          type: 'warning',
          text: 'Ops! ' + this.validationErrors,
        });
      }
      
      this.save( this.location );
    },
    validate() {
      const errors = [];
      if (!this.location.name) errors.push("Nome inválido");

      if (errors) this.validationErrors = errors;
      else this.validationErrors = [];

      return !this.validationErrors.length;
    },
  },
}
</script>
