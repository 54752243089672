<template>
	<div>
		<div class="form-group">
			<button type="button" class="btn btn-primary" v-on:click="inserting = true">Adicionar horário</button>
		</div>
		<div class="form-group" v-if="inserting">
			<div class="row">
				<div class="form-group col-md-3">
					<label>Dia da semana</label>
					<select class="form-control" v-model="availability.weekday" required="required">
						<option value="0">Domingo</option>
						<option value="1">Segunda-feira</option>
						<option value="2">Terça-feira</option>
						<option value="3">Quarta-feira</option>
						<option value="4">Quinta-feira</option>
						<option value="5">Sexta-feira</option>
						<option value="6">Sábado</option>
					</select>
				</div>
				<div class="form-group col-md-2">
					<label for="name">Hora início</label>
					<input type="time" class="form-control" id="location-availability-start-time" placeholder="Nome" v-model="availability.starttime" autofocus="autofocus" required="required">
				</div>
				<div class="form-group col-md-2">
					<label for="name">Hora fim</label>
					<input type="time" class="form-control" id="location-availability-end-time" placeholder="Nome" v-model="availability.endtime" autofocus="autofocus" required="required">
				</div>
        <div class="form-group col-md-2">
          <label class="name" for="inactive">Indisponível</label>
					<select class="form-control" v-model="availability.unavailable" required="required">
						<option value="false" selected >Não</option>
            <option value="true" >Sim</option>
          </select>
				</div>
				<div class="col-md-3">
					<button type="button" class="btn btn-primary mt-4" v-on:click="saveAvailability">Salvar</button>
					<button type="button" class="btn btn-default mt-4" v-on:click="inserting = false">Cancelar</button>
				</div>
			</div>
		</div>
	</div>            
</template>

<script>
import ApiAxios from '../../services/api-axios';
import { sAlert } from '../utils/jalerts';

export default {
  props: {
    location_id: String,
  },
  watch: {
    inserting() {
      this.clearForm();
    }
  },
  data() {
    return {
      inserting: false,
      availability: {
        weekday: 0,
        starttime: '',
        endtime: '',
        unavailable: 'false'
      },
    }
  },
  methods: {
    saveAvailability() {
		let locationAvailability = {
			... this.availability,
      location_id: this.location_id,
			allday: false,
      inactive: false
    };
		ApiAxios.post(`locationAvailabilities`, { locationAvailability })
			.then(() => {
				this.clearForm();
				this.inserting = false;
				this.$emit('saved');
			})
			.catch((e) => {
				if (e.response.status == 403) {
					return sAlert({ type: 'warning', text: 'Ops, ' + e.response.data });  
				}
				sAlert({ type: 'danger', text: 'Ops, houve um erro: ' + e.response.data });
			});
    },
    clearForm() {
      this.availability = {
        weekday: 0,
        starttime: '',
        endtime: '',
        unavailable: 'false'
      };
    }
  }
}
</script>
